@import '~@styles/breakpoints';

.Section {
  composes: Section from '~@styles/sections.module.scss';
}

.Container {
  composes: Container from '~@styles/sections.module.scss';
}

.Title {
  composes: H2 from '~@styles/typography.module.scss';
}

.List {
  margin: 3rem auto;
  display: grid;

  @include media('<=md') {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
    margin-bottom: 0;
  }
  @include media('>md') {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
  }
  @include media('>xl') {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3rem;
  }

  a {
    &:last-child {
      p {
        @include media('<md') {
          margin-bottom: 0;
        }
      }
    }
  }
}

.Right {
  grid-area: RightRelated;

  @include media('<md') {
    float: none;
    width: 100%;
    position: relative;
    top: auto;
    right: auto;
    overflow: auto;
  }

  .Title {
    font-size: var(--large-post-title-default-size);
    line-height: 1.1;
  }

  .List {
    margin: 3rem auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
  }
}
